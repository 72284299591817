import { Global, useTheme } from "@emotion/react";
import { DARK_MODE_URI } from "constants/config";
import { useRouter } from "next/router";
import { useMemo } from "react";

const GlobalCSS: React.FC = () => {
  const theme = useTheme();
  const router = useRouter();
  const bg = useMemo(() => {
    return DARK_MODE_URI.some((i) => i === router.pathname) ? theme.system.black : theme.system.white;
  }, [router.pathname]);

  return (
    <Global
      styles={{
        "html,body": {
          // background: bg
        },
        body: {
          position: "relative",
          lineHeight: 1.5
        }
      }}
    />
  );
};

export default GlobalCSS;
