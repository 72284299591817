import React, { useEffect, useRef, useState } from "react";
import { IntlProvider } from "react-intl";
import { useUIKit, Locales } from "@oxfun/uikit";
import { IntlErrorCode } from "@formatjs/intl";
import { useRouter } from "next/router";
import { useUpdateEffect } from "ahooks";

type TProps = {
  children?: React.ReactNode;
  json?: Record<string, string>;
};

const I18nProvider: React.FC<TProps> = ({ children, json }) => {
  const { locale } = useUIKit();
  const router = useRouter();

  const [messages, setMessages] = useState(json);
  const messageMap = useRef<Locales>();

  useUpdateEffect(() => {
    setMessages(messageMap.current?.get(locale));
  }, [locale]);

  useEffect(() => {
    messageMap.current = new Locales("root");
  }, []);

  useUpdateEffect(() => {
    router.replace(router.asPath, router.asPath, { locale, shallow: !router.asPath.includes("/press") });
  }, [locale]);

  return (
    <IntlProvider
      key={locale}
      locale={locale}
      messages={messages}
      onError={(e) => {
        if (e.code === IntlErrorCode.MISSING_TRANSLATION) {
          return;
        }
      }}
    >
      {children}
    </IntlProvider>
  );
};

export default I18nProvider;
