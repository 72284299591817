import type { AppContext, AppProps } from "next/app";
import {
  Provider,
  ThemeProvider,
  RecaptchaProvider,
  IntercomProvider,
  AccountIo,
  __DEV__,
  ILocale,
  System,
  DOMAIN
} from "@oxfun/uikit";
import React, { useMemo } from "react";
import theme from "styles/theme";
import I18nProvider from "providers/I18nProvider";
import BigNumber from "bignumber.js";
import Header from "widgets/Header/Header";
import SentryErrorBoundaryProvider from "providers/SentryErrorBoundaryProvider";
import GlobalCSS from "styles/GlobalCSS";
import Footer from "widgets/Footer/Footer";
import { DARK_MODE_URI, NO_RESET } from "constants/config";
import HistoryManagerProvider from "providers/HistoryManagerProvider";
import App from "next/app";
import cookie from "cookie";
import SEO from "widgets/SEO";
import { useRouter } from "next/router";
import { GoogleAnalytics, GoogleTagManager } from "@next/third-parties/google";

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
  ROUNDING_MODE: BigNumber.ROUND_DOWN
});

type TProps = AppProps & {};

const _APP = ({ Component, ...rest }: TProps) => {
  const isDarkModeUri = useMemo(() => {
    return DARK_MODE_URI.some((i) => i === rest.router.pathname);
  }, [rest.router.pathname]);

  const isNoReset = useMemo(() => {
    return NO_RESET.some((i) => i === rest.router.pathname);
  }, [rest.router.pathname]);

  const { locale } = useRouter();
  return (
    <Provider nextLocale={locale as ILocale}>
      {/* <GoogleAnalytics trackPageViews /> */}
      <I18nProvider json={rest.pageProps?.json}>
        <SEO />
        <ThemeProvider mergeTheme={theme} hardThemeMode={isDarkModeUri ? "dark" : "light"}>
          <GlobalCSS />
          <IntercomProvider />
          <SentryErrorBoundaryProvider>
            <RecaptchaProvider>
              <AccountIo />
              <HistoryManagerProvider>
                <System.Cookies />
                <Header />
                <Component {...rest} />
                <Footer />
              </HistoryManagerProvider>
            </RecaptchaProvider>
          </SentryErrorBoundaryProvider>
        </ThemeProvider>
      </I18nProvider>
      <GoogleAnalytics gaId="G-JTKPTZCC04" />
      <GoogleTagManager gtmId="GTM-WQC7W48J" />
    </Provider>
  );
};

_APP.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  try {
    const locale = (appContext.router.locale === "default" ? "en" : appContext.router.locale) || "en";
    const res = await fetch(DOMAIN?.CDN + `/locales/root/${locale.replace("-", "_")}.json?ts=${new Date().getTime()}`);
    const json = await res.json();
    appProps.pageProps.json = json;
  } catch (error) {
    console.log(error);
  }

  const cookieParse = cookie.parse(appContext.ctx.req?.headers.cookie || "");
  appProps.pageProps.theme = cookieParse["theme"] || "dark";
  return { ...appProps };
};

export default _APP;
