import { DOMAIN } from "@oxfun/uikit";

export const WSS_URL = DOMAIN?.WSS + "/v2/websocket";

export const URL = {
  MARKET: DOMAIN?.UI + "/markets",
  CONNECT_WALLET: `/connect-wallet`,
  KYC: DOMAIN?.UI + "/account/kyc",
  DEPOSIT: DOMAIN?.UI + "/wallet/deposit",
  BUY_OX: "https://app.uniswap.org/explore/tokens/ethereum/0xba0dda8762c24da9487f5fa026a9b64b695a07ea",
  TRADE_PERPS: DOMAIN?.UI + "/markets/BTC-USD-SWAP-LIN",
  DEPOSIT_OX: DOMAIN?.UI + "/wallet/deposit?coin=OX",
  API_MANAGEMENT: DOMAIN?.UI + "/user/home/apiManagement",
  OX_BRIDGE: "/ox-bridge",
  BUY_OX_PAGE: "/buy-ox",
  YIELD: "/yield"
};

export const PAGE_KEY = "__ROOT:";

export const REQUEST_CACHE_STALETIME = 3_000;
export const DARK_MODE_URI = [
  "/",
  "/referral",
  "/yield",
  "/leaderboards",
  "/connect-wallet",
  "/login",
  "/vaults",
  "/vaults/detail",
  "/vaults/profile/[id]",
  "/vaults/profile",
  "/vaults/master-trader",
  "/buy-ox",
  "/prison",
  "/ox-bridge",
  "/download",
  "/pools",
  "/learn",
  "/learn/[slug]",
  "/chain",
  "/chain/faucet",
  "/chain/points",
  "/analytics",
  "/convert-ox",
  "/newhomepage",
  "/egirl",
  "/airdrop/friend"
];
export const NO_RESET = ["/learn/[slug]"];
export const TWITTER = "https://x.com/";
export const TRADE_TYPE_SOURCE: { [key: number]: string } = {
  0: "Trade",
  2: "Borrow",
  10: "AMM+",
  22: "Delivery",
  102: "Borrow Liquidation",
  103: "Liquidation",
  101: "Auto Borrow",
  111: "Auto Repay"
};
export const HIDE_HEADER_URI = ["/connect-wallet", "/login"];

export const HIDE_FOOTER_URI = ["/connect-wallet", "/login"];
