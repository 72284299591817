import { ErrorTracking, useAccount } from "@oxfun/uikit";
import { configureScope, ErrorBoundary } from "@sentry/nextjs";
import { useEffect } from "react";

const SentryErrorBoundaryProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { accountId } = useAccount();

  useEffect(() => {
    configureScope((scope) =>
      scope.setUser({
        accountId: accountId
      })
    );
  }, [accountId]);

  return (
    <ErrorBoundary
      beforeCapture={(scope) => {
        scope.setLevel("fatal");
      }}
      fallback={({ eventId }) => <ErrorTracking trackingId={eventId} />}
    >
      {children}
    </ErrorBoundary>
  );
};

export default SentryErrorBoundaryProvider;
