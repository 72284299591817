var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"QG94ZnVuL3Jvb3QyZTliOWQx"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/root";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { Dedupe, BrowserTracing } from "@sentry/nextjs";
import { ENVIRONMENT } from "@oxfun/uikit";

/** @type {import('@sentry/nextjs').BrowserOptions} */
Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  integrations: [new BrowserTracing(), new Dedupe()],
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT.toLocaleLowerCase(),
  enabled: ENVIRONMENT === "PROD",
  ignoreErrors: [
    // "Network Error",
    // "timeout of 30000ms exceeded",
    // "Request failed with status code 401",
    // "Non-Error promise rejection captured with value: Timeout"
  ]
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
