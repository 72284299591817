import { NextPage } from "next";
import { useRouter } from "next/router";
import { useEffect } from "react";
import pubsub from "pubsub-js";
import { BANNER } from "@oxfun/uikit";

const HistoryManagerProvider: NextPage<{ children?: React.ReactNode }> = ({ children }) => {
  const router = useRouter();

  const updateBanner = () => {
    pubsub.publish(BANNER.EVENT, BANNER.REQUEST);
  };

  useEffect(() => {
    router.events.on("routeChangeStart", updateBanner);
    return () => {
      router.events.off("routeChangeStart", updateBanner);
    };
  }, []);
  return <>{children}</>;
};

export default HistoryManagerProvider;
