import styled from "@emotion/styled";
import { System } from "@oxfun/uikit";
import { ConnectWalletButton, ConnectWalletModal } from "@oxfun/web3";
import { HIDE_HEADER_URI } from "constants/config";
import { useRouter } from "next/router";
import React from "react";

type TProps = {};

const Wrapper = styled.header`
  position: relative;
  z-index: 100;
`;

const Header: React.FC<TProps> = () => {
  const { pathname } = useRouter();

  return !HIDE_HEADER_URI.some((p) => p === pathname) ? (
    <Wrapper>
      <System.Header ConnectWallet={<ConnectWalletButton />} />
      <ConnectWalletModal />
    </Wrapper>
  ) : (
    <></>
  );
};

export default Header;
