import styled from "@emotion/styled";
import { System } from "@oxfun/uikit";
import { HIDE_FOOTER_URI } from "constants/config";
import { useRouter } from "next/router";
import React from "react";

type TProps = {};
const Wrapper = styled.footer``;

const Footer: React.FC<TProps> = () => {
  const { pathname } = useRouter();

  return !HIDE_FOOTER_URI.some((p) => p === pathname) ? (
    <Wrapper>
      <System.Footer />
    </Wrapper>
  ) : (
    <></>
  );
};

export default Footer;
