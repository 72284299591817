import { DOMAIN, LANGUAGES } from "@oxfun/uikit";
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";
import { useIntl } from "react-intl";

const SPLASH = [
  { width: 320, height: 568, ratio: 2 },
  { width: 375, height: 667, ratio: 2 },
  { width: 621, height: 1104, ratio: 3 },
  { width: 375, height: 812, ratio: 3 },
  { width: 414, height: 896, ratio: 2 },
  { width: 414, height: 896, ratio: 3 },
  { width: 768, height: 1024, ratio: 2 },
  { width: 834, height: 1112, ratio: 2 },
  { width: 834, height: 1194, ratio: 2 },
  { width: 1024, height: 1366, ratio: 2 }
];

const SEO: React.FC = () => {
  const router = useRouter();
  const intl = useIntl();
  const languageAlternates = Object.keys(LANGUAGES)
    .map((p) => {
      return { hrefLang: p, href: DOMAIN?.UI + `/${p}` + (router.asPath === "/" ? "" : router.asPath) };
    })
    .concat({ hrefLang: "x-default", href: DOMAIN?.UI + `/en` + (router.asPath === "/" ? "" : router.asPath) });

  return (
    <NextSeo
      title={intl.formatMessage({ defaultMessage: "OX.FUN - NextGen perps trading" })}
      description={intl.formatMessage({
        defaultMessage: "Trade MEME Coins on OXFUN Now."
      })}
      themeColor="#141619"
      openGraph={{
        type: "website",
        url: languageAlternates.find((p) => p.hrefLang === router.locale)?.href,
        siteName: "OX.FUN",
        description: intl.formatMessage({
          defaultMessage: "Trade MEME Coins on OXFUN Now."
        }),
        images: [
          {
            url: DOMAIN?.CDN + "/static/images/open-graph.png"
          }
        ]
      }}
      additionalLinkTags={[
        { rel: "manifest", href: "/manifest.webmanifest" },
        {
          rel: "shortcut icon",
          href: DOMAIN?.CDN + "/static/images/favicon.ico"
        },
        {
          rel: "icon",
          sizes: "16x16",
          type: "image/png",
          href: DOMAIN?.CDN + "/static/images/favicon-16x16.png"
        },
        {
          rel: "icon",
          sizes: "32x32",
          type: "image/png",
          href: DOMAIN?.CDN + "/static/images/favicon-32x32.png"
        },
        {
          rel: "apple-touch-icon",
          sizes: "180x180",
          href: DOMAIN?.CDN + "/static/images/application-180x180.png"
        },
        ...SPLASH.map(({ width, height, ratio }) => ({
          rel: "apple-touch-startup-image",
          media: `(device-width: ${width}px) and (device-height: ${height}px) and (-webkit-device-pixel-ratio: ${ratio})`,
          sizes: `${width * ratio}x${height * ratio}`,
          href: DOMAIN?.CDN + `/static/images/apple-launch-${width}x${height}@${ratio}x.png`
        }))
      ]}
      twitter={{
        site: "OX.FUN",
        handle: "OX.FUN",
        cardType: "summary_large_image"
      }}
      additionalMetaTags={[
        // {
        //   name: "keywords",
        //   content: intl.formatMessage({
        //     defaultMessage: "OX.FUN, Crypto, Perp, Exchange, BTC, ETH, XRP, USDC, USDT, Earn Crypto, Trade Crypto"
        //   })
        // },
        { name: "Author", content: "OX.FUN" },
        {
          name: "Copyright",
          content: "ox.fun"
        },
        {
          httpEquiv: "x-ua-compatible",
          content: "IE=edge; chrome=1"
        },
        {
          name: "viewport",
          // content: "width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no"
          content: "width=device-width, initial-scale=1.0"
        },
        {
          name: "full-screen",
          content: "yes"
        },
        {
          name: "browsermode",
          content: "application"
        },
        {
          name: "x5-orientation",
          content: "portrait"
        },
        {
          name: "x5-fullscreen",
          content: "true"
        },
        {
          name: "x5-page-mode",
          content: "app"
        },
        {
          name: "apple-mobile-web-app-capable",
          content: "yes"
        },
        {
          name: "apple-mobile-web-app-status-bar-style",
          content: "black-translucent"
        }
      ]}
      canonical={languageAlternates.find((p) => p.hrefLang === router.locale)?.href}
      languageAlternates={languageAlternates}
    />
  );
};

export default SEO;
